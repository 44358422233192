<template>
  <div id="app">
    <Navbar @navigate="scrollToSection" />
    <router-view />
    <Footer />
    <ScrollToTopButton />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import ScrollToTopButton from './components/ScrollToTopButton.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    ScrollToTopButton,
  },
  mounted() {
    this.scrollToHash();
    this.$router.afterEach(() => {
      this.scrollToHash();
    });
  },
  methods: {
    scrollToHash() {
      this.$nextTick(() => {
        const hash = this.$route.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            const offset = 65; // Ajuste para a altura do navbar
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        }
      });
    },
    scrollToSection(sectionId) {
      this.$router.push({ path: '/', hash: `#${sectionId}` }).then(() => {
        this.$nextTick(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            const offset = 65; // Ajuste para a altura do navbar
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        });
      });
    }
  }
};
</script>

<style>
body {
  padding-top: 65px !important; 
}

/* Estilos personalizados para o scroll */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #1f2a38;
}

::-webkit-scrollbar-thumb {
  background: #686c8c;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
  background: #686c8c;
}
</style>
