<template>
  <nav class="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 p-4 shadow-lg fixed top-0 w-full z-50">
    <div class="container mx-auto flex justify-between items-center">
      <div class="flex items-center space-x-3">
        <img src="@/assets/files/img/LogoWhiteCenter.png" alt="Logo" class="h-9">
      </div>
      <div class="hidden md:flex space-x-4">
        <router-link to="/" class="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('sobre-mim')">Home</router-link>
        <router-link to="/" class="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('projetos')">Projetos</router-link>
        <router-link to="/" class="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('experiencia')">Experiência</router-link>
        <router-link to="/" class="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('estudos')">Estudos</router-link>
        <router-link to="/" class="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('skills')">Skills</router-link>
        <router-link to="/" class="text-white bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out animate__animated animate__pulse animate__infinite" @click="handleNavigation('contato')">Contato</router-link>
      </div>
      <div class="md:hidden">
        <button @click="toggleMenu" class="text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="menuOpen" class="md:hidden">
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('sobre-mim')">Home</router-link>
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('projetos')">Projetos</router-link>
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('experiencia')">Experiência</router-link>
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('estudos')">Estudos</router-link>
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out" @click="handleNavigation('skills')">Skills</router-link>
      <router-link to="/" class="block text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out animate__animated animate__pulse animate__infinite" @click="handleNavigation('contato')">Contato</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    handleNavigation(sectionId) {
      this.$emit('navigate', sectionId);
      this.menuOpen = false; // Fechar o menu após clicar no link
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
};
</script>

<style scoped>
/* Estilo específico para o navbar */
</style>
