<template>
  <button
    v-if="visible"
    @click="scrollToTop"
    class="fixed bottom-4 right-2 w-8 h-8 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center transition-opacity duration-300 hover:bg-blue-700"
    :class="{ 'visible': visible }"
  >
    <font-awesome-icon icon="arrow-up" />
  </button>
</template>

<script>
export default {
  name: 'ScrollToTopButton',
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      this.visible = window.scrollY > 200;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() { 
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
button {
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 3000 !important;
}
button.visible {
  opacity: 1;
}
</style>
