<template>
  <footer class="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white text-center p-4 shadow-lg">
    <div class="flex justify-center space-x-4 mb-2">
      <a href="https://www.linkedin.com/in/lucasvazti/" target="_blank" class="transition duration-300 ease-in-out transform hover:scale-110">
        <img src="@/assets/files/icon/linkedin.png" alt="LinkedIn" class="h-6 w-6">
      </a>
      <a href="https://wa.me/5573999326446?text=Ol%C3%A1%20Lucas" target="_blank" class="transition duration-300 ease-in-out transform hover:scale-110">
        <img src="@/assets/files/icon/whatsapp.png" alt="WhatsApp" class="h-6 w-6">
      </a>
      <a href="https://github.com/lucasvaz-net/" target="_blank" class="transition duration-300 ease-in-out transform hover:scale-110">
        <img src="@/assets/files/icon/git.png" alt="WhatsApp" class="h-6 w-6">
      </a>
    </div>
    <p class="mt-2">&copy; 2024 Lucas Vaz</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
/* Adicione qualquer estilo específico do componente aqui */

</style>
