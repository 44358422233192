import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import './assets/tailwind.css';
import './assets/styles/global.css';
import 'animate.css';
import { FontAwesomeIcon } from './fontawesome';

const app = createApp(App);
const head = createHead();

app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(head);

router.isReady().then(() => {
  app.mount('#app');
  document.dispatchEvent(new Event('render-event'));
});
