<template>
  <!-- Seção Estudos (Carrossel) -->
  <section id="estudos" class="bg-gray-900 text-white p-8 min-h-screen flex items-center justify-center">
    <div class="w-full">
       <h2 class="text-4xl font-bold text-center">
      <font-awesome-icon :icon="['fas', 'book-open']" class="mr-2" />
      Estudos
    </h2>
      <div class="relative mt-8">
        <swiper
          :breakpoints="{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 4 }
          }"
          :space-between="30"
          :loop="true"
          :autoplay="{ delay: 3000 }"
          :pagination="{ clickable: true }"
          :speed="800"
          class="estudos-swiper"
        >
          <swiper-slide v-for="(estudo, index) in estudos" :key="index">
            <div class="bg-gray-700 text-white rounded-lg shadow-md p-4 border border-gray-600 min-h-[350px] flex flex-col justify-between">
              <div class="flex justify-between items-start">
                <h4 class="text-xl font-semibold mb-2">{{ estudo.titulo }}</h4>
              </div>
              <div class="flex-1 flex flex-col justify-center my-4">
                <p class="mb-2">{{ estudo.descricao }}</p>
                <p class="mb-2"><strong>Local:</strong> {{ estudo.local }}</p>
                <p class="mb-2 mt-2">{{ formatDate(estudo.dataInicio) }} - {{ formatDate(estudo.dataFinal) }}</p>
              </div>
              <div class="flex justify-center mt-2 space-x-2">
                <img v-for="tec in estudo.listaDeTecnologias" :key="tec" :src="getIconPath(tec)" :alt="tec" class="w-6 h-6">
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import { getEstudos } from '@/services/api';

// Configuração dos módulos do Swiper
SwiperCore.use([Autoplay, Pagination]);

export default {
  name: 'EstudosPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      estudos: [],
    };
  },
  async created() {
    this.fetchEstudos();
  },
  methods: {
    async fetchEstudos() {
      try {
        this.estudos = await getEstudos();
      } catch (error) {
        console.error('Erro ao buscar estudos:', error);
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'numeric' }; // Ajuste para exibir apenas mês e ano
      return new Date(date).toLocaleDateString('pt-BR', options);
    },
    getIconPath(titulo) {
      try {
        return require(`@/assets/files/icon/${titulo.toLowerCase()}.png`);
      } catch (e) {
        console.error(`Ícone não encontrado para: ${titulo}`);
        return '';
      }
    }
  }
};
</script>

<style scoped>
.estudos-swiper {
  padding-bottom: 40px; /* Adicione um padding extra na parte inferior para garantir que a paginação não sobreponha os cards */
}

.estudos-swiper .swiper-pagination {
  bottom: 10px; /* Ajuste este valor conforme necessário para posicionar as bolinhas abaixo dos cards */
}
</style>
