// src/fontawesome.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { 
  faGlobe, 
  faBookOpen, 
  faUser,     
  faBriefcase,
  faTasks,    
  faStar,     
  faEnvelope,
  faArrowUp   // Adicionando o ícone de seta para cima
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faGithub, 
  faGlobe, 
  faBookOpen,  
  faUser,      
  faBriefcase, 
  faTasks,     
  faStar,      
  faEnvelope,
  faArrowUp    // Adicionando o ícone de seta para cima à biblioteca
);

export { FontAwesomeIcon };
