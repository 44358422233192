<template>
  <section id="projetos" class="bg-gray-900 text-white p-8 min-h-screen flex items-center justify-center">
    <div class="w-full">
      <h2 class="text-4xl font-bold text-center">
      <font-awesome-icon :icon="['fas', 'tasks']" class="mr-2" />
      Projetos</h2>
      <div class="relative mt-8">
        <swiper
          :breakpoints="{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 4 }
          }"
          :space-between="30"
          :loop="true"
          :autoplay="{ delay: 3000 }"
          :pagination="{ clickable: true }"
          :speed="800"
          class="projetos-swiper"
        >
          <swiper-slide v-for="(proj, index) in projetos" :key="index">
            <div class="bg-gray-700 text-white rounded-lg shadow-md p-4 border border-gray-600 min-h-[350px] flex flex-col justify-between">
              <div class="flex justify-between items-start">
                <h4 class="text-xl font-semibold mb-2">{{ proj.titulo }}</h4>
                <div class="flex space-x-2">
                  <a :href="proj.linkGit" class="text-blue-400 hover:text-blue-600" target="_blank">
                    <font-awesome-icon :icon="['fab', 'github']" class="w-6 h-6" />
                  </a>
                  <a :href="proj.linkWeb" class="text-blue-400 hover:text-blue-600" target="_blank">
                    <font-awesome-icon :icon="['fas', 'globe']" class="w-6 h-6" />
                  </a>
                </div>
              </div>
              <div class="flex-1 flex flex-col justify-center my-4">
                <p class="mb-2">{{ proj.descricao }}</p>
                <p class="mb-2 mt-2">{{ formatDate(proj.dataInicio) }} - {{ formatDate(proj.dataFinal) }}</p>
              </div>
              <div class="flex justify-center mt-2 space-x-2">
                <img v-for="tec in proj.listaDeTecnologias" :key="tec" :src="getIconPath(tec)" :alt="tec" class="w-6 h-6">
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import { getProjetos } from '@/services/api';

// Configuração dos módulos do Swiper
SwiperCore.use([Autoplay, Pagination]);

export default {
  name: 'ProjetosPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      projetos: [],
    };
  },
  async created() {
    this.fetchProjetos();
  },
  methods: {
    async fetchProjetos() {
      try {
        this.projetos = await getProjetos();
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
      }
    },
    getIconPath(titulo) {
      try {
        return require(`@/assets/files/icon/${titulo.toLowerCase()}.png`);
      } catch (e) {
        console.error(`Ícone não encontrado para: ${titulo}`);
        return '';
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'numeric' }; // Ajuste para exibir apenas mês e ano
      return new Date(date).toLocaleDateString('pt-BR', options);
    }
  }
};
</script>

<style scoped>
.projetos-swiper {
  padding-bottom: 40px; /* Adicione um padding extra na parte inferior para garantir que a paginação não sobreponha os cards */
}

.projetos-swiper .swiper-pagination {
  bottom: 10px; /* Ajuste este valor conforme necessário para posicionar as bolinhas abaixo dos cards */
}
</style>
