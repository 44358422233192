<template>
  <div>
    <NavbarComponent />
    <div id="sobre-mim">
      <SobreMim />
    </div>
    <div id="projetos">
      <Projetos />
    </div>
    <div id="experiencia">
      <Experiencias />
    </div>
    <div id="estudos">
      <Estudos />
    </div>
    <div id="skills">
      <Skills />
    </div>
    <div id="contato">
      <Contatos />
    </div>
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';
import SobreMim from '@/components/SobreMim.vue';
import Skills from '@/components/Skills.vue';
import Projetos from '@/components/Projetos.vue';
import Experiencias from '@/components/Experiencias.vue';
import Estudos from '@/components/Estudos.vue';
import Contatos from '@/components/Contatos.vue';

useHead({
  title: 'Home',
  meta: [
    { name: 'description', content: 'Lucas Vaz é um profissional de TIC com mais de 5 anos de experiência. Ele possui habilidades em desenvolvimento de software, análise de sistemas e engenharia de computação. Confira seu portfólio para saber mais sobre seus projetos, habilidades e experiências profissionais.' },
    { name: 'keywords', content: 'lucas, vaz, tic, ti, computação, engenharia, software, desenvolvedor, analista' },
    { name: 'author', content: 'Lucas Vaz' },
    { name: 'robots', content: 'index, follow' },
    { property: 'og:title', content: 'Lucas Vaz' },
    { property: 'og:description', content: 'Portfólio profissional de Lucas Vaz, mostrando suas habilidades em TIC, desenvolvimento de software e engenharia de computação.' },
    { property: 'og:image', content: 'https://lucasvaz.dev.br/logowhite.png' },
    { property: 'og:url', content: 'https://lucasvaz.dev.br/' },
    { property: 'og:type', content: 'website' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'Página Inicial' },
    { name: 'twitter:description', content: 'Portfólio profissional de Lucas Vaz, mostrando suas habilidades em TIC, desenvolvimento de software e engenharia de computação.' },
    { name: 'twitter:image', content: 'https://lucasvaz.dev.br/logowhite.png' },
    { name: 'twitter:site', content: '@Lucax_Dev' }  
  ],
  link: [
    { rel: 'icon', href: 'https://lucasvaz.dev.br/favicon.ico' }
  ]
});
</script>

<style scoped>
.swiper-pagination-bullet {
  background: white;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.border-gray-600 {
  border-color: #4a5568;
}

section {
  font-family: 'Qanelas', sans-serif;
}
</style>
