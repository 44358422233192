<template>
  <section class="bg-gray-800 text-white p-8">
    <div class="container mx-auto">
      <h2 class="text-4xl font-bold mb-4 text-center">
        <font-awesome-icon :icon="['fas', 'star']" class="mr-2" />
      Skills</h2>
      <div class="flex flex-wrap justify-center">
        <div v-for="tech in tecnologiasFiltradasOrdenadas" :key="tech.id" class="w-28 p-2 m-2 text-center transition-transform transform hover:scale-110 rounded-lg bg-gray-700 shadow-lg">
          <img :src="getIconPath(tech.titulo)" :alt="tech.titulo" class="w-20 h-20 mb-2 mx-auto">
          <div class="w-full bg-gray-800 rounded-full h-2.5 mb-2">
            <div class="bg-blue-600 h-2.5 rounded-full" :style="{ width: `${tech.nivelHabilidade}%` }"></div>
          </div>
          <p class="text-sm mt-2">{{ getNivelHabilidadeText(tech.nivelHabilidade) }}</p>
          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getTecnologias } from '@/services/api';

export default {
  name: 'SkillsPage',
  data() {
    return {
      tecnologias: [],
      tecnologiasFiltradasOrdenadas: [],
    };
  },
  async created() {
    try {
      this.tecnologias = await getTecnologias();
      this.tecnologiasFiltradasOrdenadas = this.filtrarEOrdenarTecnologias(this.tecnologias);
    } catch (error) {
      console.error('Erro ao carregar tecnologias:', error);
    }
  },
  methods: {
    getIconPath(titulo) {
      return require(`@/assets/files/icon/${titulo.toLowerCase()}.png`);
    },
    filtrarEOrdenarTecnologias(tecnologias) {
      const ordemTipos = ["Banco de Dados", "Back-End", "Front-End", "Ferramenta", "Versionamento", "Cloud"];
      return tecnologias
        .filter(tech => ordemTipos.includes(tech.tipo))
        .sort((a, b) => ordemTipos.indexOf(a.tipo) - ordemTipos.indexOf(b.tipo));
    },
    getNivelHabilidadeText(nivelHabilidade) {
      if (nivelHabilidade <= 20) {
        return 'Aspirante';
      } else if (nivelHabilidade <= 40) {
        return 'Familiarizado';
      } else if (nivelHabilidade <= 60) {
        return 'Junior';
      } else if (nivelHabilidade <= 80) {
        return 'Pleno';
      } else {
        return 'Senior';
      }
    }
  }
};
</script>

<style scoped>
/* Adicione qualquer estilo adicional necessário aqui */
</style>
