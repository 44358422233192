<template>
  <!-- Seção Formulário de Contato -->
  <section class="bg-gray-900 text-white p-8 min-h-screen flex items-center justify-center">
    <div class="w-full">
      <h2 class="text-4xl font-bold text-center">
        <font-awesome-icon :icon="['fas', 'envelope']" class="mr-2" />
      Contato</h2>
      <form target="_blank" action="https://formsubmit.co/contato@lucasvaz.dev.br" method="POST" class="mt-8 max-w-lg mx-auto">
        <div class="mb-4">
          <label for="name" class="block text-sm font-bold">Nome</label>
          <input type="text" name="name" class="w-full p-2 border border-gray-300 rounded-md" required />
        </div>
        <div class="mb-4">
          <label for="email" class="block text-sm font-bold">Email</label>
          <input type="email" name="email" class="w-full p-2 border border-gray-300 rounded-md" required />
        </div>
        <div class="mb-4">
          <label for="message" class="block text-sm font-bold">Mensagem</label>
          <textarea name="message" class="w-full p-2 border border-gray-300 rounded-md" required></textarea>
        </div>
        <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded animate-pulse">
          Enviar
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContatosPage',
};
</script>

<style scoped>
textarea, input {
  color: black;
}
</style>
